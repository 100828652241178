.panel{
    position: absolute;
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 10px;
    color:#000;
    /* top: 60px; */
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #f1f1f1;
    width:  250px;
    height: 100vh;

    z-index: 11;
    overflow: scroll;
    transform: translate3d(0, 0, 0);
}
.panelClosed{
    position: fixed;
    padding-top: 30px;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #f1f1f1;
    width:  55px;
    height: 100vh;
    z-index: 11;
    overflow: "hidden";
    transform: translate3d(0, 0, 0);
}
.clickable:hover{
    cursor: pointer;
}
.panelClosedInsmall{
    position: fixed;
    padding-top: 40px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #f1f1f1;
    width:  0;
    height: 100vh;
    z-index: 11;
    transform: translate3d(0, 0, 0);
}